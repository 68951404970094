import React, { useState } from 'react';
import {
  Clock, Users, ShoppingBag, TrendingUp,
  Activity, Settings, Clipboard, BarChart,
  CheckCircle, AlertCircle, RefreshCw, Shield,
  Smile, DollarSign, Cpu, TrendingDown, Menu, BarChart2, AlertTriangle, ChevronRight
} from "lucide-react";
import './css/App.css'
import eatzap from './assets/images/eatzap-image (1).png'
import logo from './assets/images/eatzaplogo.png'
import img9 from './assets/images/mgr-eatzap-9.jpeg'
import img10 from './assets/images/mgr-eatzap-10.jpeg'
import img12 from './assets/images/mgr-eatzap-12.jpeg'
import img13 from './assets/images/mgr-eatzap-13.jpg'
import img14 from './assets/images/mgr-eatzap-14.jpg'

const App = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div className="texabyte-website">
      {/* Header */}
      <nav className="site-header">
        <div className="header-content">
          <div className="logo">Texabyte</div>
          <div className={`nav-links ${isMenuOpen ? 'active' : ''}`}>
            <a href="#about">About</a>
            <a href="#products">Products</a>
            <a href="#contact">Contact</a>
          </div>
          <button
            className="mobile-menu"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <Menu className="menu-icon" />
          </button>
        </div>
      </nav>

      {/* Hero Section */}
      <header className="hero-section">
        <div className="hero-content">
          <h1 className="hero-title">Technology Solutions That Make a Difference</h1>
          <p className="hero-subtitle">Building practical solutions that transform everyday experiences</p>
          <button className="cta-button">Explore Our Solutions</button>
        </div>
      </header>

      {/* About Texabyte */}
      <section className="about-section">
        <div className="about-content">
          <h2 className="section-title">About Texabyte</h2>
          <p className="about-text">
            "At Texabyte, we are passionate about creating technology-driven solutions that solve real-world challenges.
            Our team combines technical expertise with practical innovation to develop products that make a meaningful impact."
          </p>
        </div>
      </section>

      {/* Product Introduction */}
      <section className="product-intro-section">
        <div className="product-intro-content">
          <div className="product-logo">
            <img src={logo} alt="Eatzap Logo" className="eatzap-logo" />
            <h2 className="product-title">Eatzap</h2>
          </div>
          <p className="product-description">
            A canteen food ordering application aimed at transforming institutional food service operations
          </p>
        </div>
      </section>

      {/* Problem Statement Section */}
      <section className="problem-section">
        <div className="problem-content">
          <h2 className="section-title">The Challenge: Managing Peak Hour Rush</h2>
          <div className="empty"></div>
          <div className="problem-imagery">
            <img src={img13} alt="Students waiting in long canteen queues" className="queue-image" />

            <div className="stats-overlay">
              <h3 className="stats-title">Impact on Operations</h3>
              <div className="stats-list">
                <div className="stats-item">
                  <Clock className="stats-icon" />
                  <span>Students spend an average of <b>3-15 minutes</b> in queues, leading to <b>delays</b> in attending classes.</span>
                </div>
                <div className="stats-item">
                  <TrendingDown className="stats-icon" />
                  <span>Many students, <b>particularly girls,</b> avoid purchasing food due to <b>long queues</b>, impacting college canteen experience.</span>
                </div>
                <div className="stats-item">
                  <AlertTriangle className="stats-icon" />
                  <span>Canteen staff face <b>high workload pressure</b> during peak hours, reducing <b>service efficiency</b>.</span>
                </div>
                <div className="stats-item">
                  <Smile className="stats-icon" />
                  <span>Students experience <b>frustration and stress</b> due to inefficient ordering, lowering overall satisfaction.</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Solution Section */}
      <section className="eatzap-section">
        <div className="eatzap-content">
          <div className="solution-header">
            <h2 className="section-title">Where EATZAP comes into play!</h2>
            <p className="eatzap-intro">
              A comprehensive platform designed to streamline food service operations and enhance customer experience
            </p>
          </div>
          <div className="eatzap-box">
            <div className="benefits-grid-left">
              <div className="benefits-column students">
                <h3 className="benefits-title">For Your Customers</h3>
                <ul className="benefits-list">
                  <li><Clock className="icon" /> Convenient pre-ordering system</li>
                  <li><Users className="icon" /> Queue-free pickup experience</li>
                  <li><ShoppingBag className="icon" /> Real-time menu access</li>
                  <li><TrendingUp className="icon" /> Seamless payment process</li>
                </ul>
              </div>

              <div className="benefits-column management">
                <h3 className="benefits-title">For Management</h3>
                <ul className="benefits-list">
                  <li><Activity className="icon" /> Optimized crowd management</li>
                  <li><Settings className="icon" /> Enhanced operational efficiency</li>
                  <li><Clipboard className="icon" /> Improved inventory control</li>
                  <li><BarChart className="icon" /> Data-driven insights</li>
                </ul>
              </div>
            </div>

            <img src={eatzap} alt="Eatzap Interface" className="solution-image" />

            <div className="benefits-grid-right">
              <div className="benefits-column staff">
                <h3 className="benefits-title">For Staff</h3>
                <ul className="benefits-list">
                  <li><CheckCircle className="icon" /> Streamlined order fulfillment</li>
                  <li><AlertCircle className="icon" /> Reduced order confusion</li>
                  <li><RefreshCw className="icon" /> Faster service turnaround</li>
                  <li><Shield className="icon" /> Secure payment handling</li>
                </ul>
              </div>

              <div className="benefits-column business">
                <h3 className="benefits-title">Business Outcomes</h3>
                <ul className="benefits-list">
                  <li><TrendingUp className="icon" /> Increased revenue potential</li>
                  <li><Smile className="icon" /> Higher customer satisfaction</li>
                  <li><DollarSign className="icon" /> Reduced operational costs</li>
                  <li><Cpu className="icon" /> Better resource utilization</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Success Story Section */}
      <section className="showcase-section">
        <h2 className="section-title">Our Client - Chef's Kitchen: Dr. M.G.R. Educational And Research Institute</h2>
        <div className="showcase-box">
          <p className="case-study-intro">
            As our valued partner, Chef's Kitchen(Dr. M.G.R. Educational And Research Institute) has experienced remarkable improvements
            in their canteen operations since implementing Eatzap. Their trust in our solution has helped us demonstrate
            the real-world impact of online food ordering within college campus.
          </p>

          <div className="image-gallery">
            <div className="gallery-item">
              <img
                src={img10}
                alt="Students using Eatzap at MGR Institute"
                className="gallery-image"
              />
              {/* <p className="image-caption">Streamlined ordering process</p> */}
            </div>
            <div className="gallery-item">
              <img
                src={img14}
                alt="Canteen staff fulfilling Eatzap orders"
                className="gallery-image"
              />
              {/* <p className="image-caption">Efficient kitchen operations</p> */}
            </div>
          </div>
          {/* <div className="gallery-item">
            <img
              src={img3}
              alt="Quick pickup counter for Eatzap orders" 
              className="gallery-image"
            />
            <p className="image-caption">Organized pickup system</p>
          </div> */}
        </div>

        <div className="impact-metrics">
          <div className="metrics-grid">
            <h3 className="metrics-title">Measured Impact</h3>
            <div className="metric-card">
              <BarChart2 className="metric-icon" />
              <h4 className="metric-value">Up to 65%</h4>
              <p className="metric-label">Faster order processing</p>
            </div>
            <div className="metric-card">
              <Smile className="metric-icon" />
              <h4 className="metric-value">Improved Experience</h4>
              <p className="metric-label">Higher student satisfaction with reduced wait times</p>
            </div>
            <div className="metric-card">
              <Settings className="metric-icon" />
              <h4 className="metric-value">Better Operations</h4>
              <p className="metric-label">Smoother workflow and reduced rush for canteen staff</p>
            </div>
          </div>
        </div>
      </section>

      {/* Innovation Section */}
      {/* <section className="innovation-section">
        <div className="innovation-content">
          <h2 className="section-title">Continuous Innovation</h2>
          <div className="innovation-card">
            <h3 className="innovation-title">Evolving With Your Needs</h3>
            <p className="innovation-description">
              We continuously enhance our platform based on real user feedback and emerging requirements,
              ensuring your dining management system stays ahead of the curve.
            </p>
            <button className="innovation-button">
              Learn About Our Latest Features <ArrowRight className="button-icon" />
            </button>
          </div>
        </div>
      </section> */}

      {/* Expansion Plans */}
      <section className="expansion-section">
        <div className="expansion-content">
          <h2 className="section-title">Looking Ahead</h2>
          <p className="expansion-text">
            Building on our successful implementation at Dr. M.G.R. Educational And Research Institute,
            we're ready to partner with more institutions to revolutionize their canteen operations.
          </p>
          <div className="contact-card">
            <h3 className="contact-title">Interested in bringing Eatzap to your campus?</h3>
            <button className="contact-button" onClick={() => { window.open("https://wa.me/+916369936523", '_blank', 'noopener noreferrer'); }}>
              Contact Us <ChevronRight className="button-icon" />
            </button>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="site-footer">
        <div className="footer-content">
          <div className="footer-brand">
            <h3 className="footer-title">Texabyte</h3>
            <p className="footer-tagline">Building tomorrow's solutions today</p>
          </div>
          <div className="footer-links">
            <h4 className="links-title">Quick Links</h4>
            <ul className="links-list">
              <li>About Us</li>
              <li>Eatzap</li>
              <li>Contact</li>
            </ul>
          </div>
          <div className="footer-contact">
            <h4 className="contact-title">Contact Us</h4>
            <p className="contact-info">josh@texabyte.in</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default App;